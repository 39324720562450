import React from 'react';

import { useNavigate } from 'react-router-dom';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import mockup_img from "../images/elproma/mockup.jpg";
import kompozycja_fotograficzna_img from "../images/elproma/kompozycja_fotograficzna.jpg";
import kompozycja_graficzna_img from "../images/elproma/kompozycja_graficzna.jpg";
import animacja from "../images/elproma/animacja.gif";

import Footer from '../components/Footer/Footer';

const ElpromaProject = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Project for <a href="http://www.elproma.com.pl/">ELPROMA Electronics Sp. z o.o.</a></p>  
                </div>

                <div className="infoframe">
                    <div className="infotext">
                       
                        <p>
                            The project won the 1st prize.
                            <br/>
                            <br/>
                            The competition organized by Warsaw School of Arts aimed to create a series of artworks related to the area of ELPROMA's activity which would decorate the company's headquarters.
                            <br/>
                            <br/>
                            Each artwork has a fragment of the ELPROMA device covered by a delicate shape of interference fringes which relates to the synchronization of the devices manufactured by ELPROMA.                            
                        </p>

                        <p>
                            <img src={mockup_img} alt="Poster 1,2 visualization"></img><br/><br/>
                            <img src={kompozycja_fotograficzna_img} alt="three posters"></img><br/><br/>
                            <img src={kompozycja_graficzna_img} alt="three posters"></img><br/><br/>
                            <img src={animacja} alt="animated gif"></img>
                        </p>

                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className='button' onClick={()=>navigate("/work")}> 
                        BACK
                    </button> 
                </div>

                <Footer />

            </div>
        )
   
}

export default ElpromaProject;