import React from 'react';

import { useNavigate } from 'react-router-dom';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import yacht_01 from '../images/yachts/yacht_1.jpg';
import yacht_02 from '../images/yachts/yacht_2.jpg';
import yacht_sys from '../images/yachts/yacht_systems.jpg';
import yacht_03 from '../images/yachts/yacht_3.jpg';

import Footer from '../components/Footer/Footer';

const YachtProject = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop smooth />

                <div className="beginning"> 
                    <p className="title">Superyacht Visualizations</p>  
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            <a href="https://yis.yachtcloud.com" target="_blanc">Yacht Information System</a> is an immersive oversight tool for superyacht owners.
                            It includes 4K video, an interactive 3D WebGL animation, and photorealistic renders of the yacht and its subsystems that were prepared
                            in our studio and embedded in the YIS software developed by <a href="https://fss.cc" target="_blanc">FSS Software House</a>.
                            <br/><br/>
                            Here we share some visuals from the project.<br/>
                        </p>

                        <p>
                            <img src={yacht_01} alt="Superyacht visualization"></img><br/><br/>
                            <img src={yacht_sys} alt="Superyacht systems visualization"></img><br/><br/>
                            <img src={yacht_02} alt="Superyacht visualization"></img><br/><br/>
                            <img src={yacht_03} alt="Superyacht WebGL visualization"></img><br/><br/>
                        </p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div>

                <Footer />

            </div>
        )
}

export default YachtProject;