import React from 'react';

import { Link, useNavigate } from 'react-router-dom';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import moon_00 from '../images/moon/moon_00_2res_banner.jpg';
import moon_01 from '../images/moon/moon_01_natural_1.jpg';
import moon_02 from '../images/moon/moon_02_natural_3.jpg';
import moon_03 from '../images/moon/moon_03_natural.jpg';
import moon_04 from '../images/moon/moon_04_bone.jpg';
import moon_05 from '../images/moon/moon_05_rdylbu_2.jpg';
import moon_06 from '../images/moon/moon_06_greys.jpg';
import moon_07 from '../images/moon/moon_07_rdylbu_1.jpg';
import Footer from '../components/Footer/Footer';

const MoonProject = () => {
    const navigate = useNavigate();
        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Making of the Moon</p>  
                </div>

                <div className="embed-container">
                    <iframe title="Moon" src="https://player.vimeo.com/video/354527906"></iframe>
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            The Moon is special this year. We searched for the highest resolution data to make it almost touchable.
                            It is ray-traced in Python with <Link to="/project/plotoptix">PlotOptiX</Link>, code is
                            on <a href="https://github.com/rnd-team-dev/plotoptix/tree/master/examples/3_projects/moon" target="_blanc">GitHub</a>,
                            and you can also read the <a href="https://medium.com/@sulej.robert/the-moon-made-twice-at-home-a2cb73b3f1e8" target="_blanc">story on Medium</a>.
                            <br/><br/>
                            <Link to="/contact">Contact us</Link> for the high-quality posters printed on the artistic paper.
                            <br/><br/>
                            Resources used in the visualization:<br/>
                            1. Natural colors of the Moon surface (~480m resolution): <a href="http://lroc.sese.asu.edu/posts/801" target="_blanc" rel="noopener noreferrer">Lunar Reconnaissance Orbiter Camera (LROC)</a>.<br/>
                            2. Surface elevation model: Lunar Orbiter Laser Altimeter (LOLA), <a href="https://astrogeology.usgs.gov/search/details/Moon/LRO/LOLA/Lunar_LRO_LOLA_Global_LDEM_118m_Mar2014/cub" target="_blanc" rel="noopener noreferrer">118m resolution data by LOLA Science Team</a>.<br/>
                            3. Star map in the background (16k resolution): <a href="https://svs.gsfc.nasa.gov/3895" target="_blanc" rel="noopener noreferrer">NASA</a> as well, surprisingly! ;)
                            <br/><br/>
                            Here we share some of the visual results.<br/>
                        </p>

                        <p>
                            <img src={moon_00} alt="Moon model ray-traced with PlotOptiX, natural colors"></img><br/><br/>
                            <img src={moon_01} alt="Moon model ray-traced with PlotOptiX, natural colors"></img><br/><br/>
                            <img src={moon_02} alt="Moon model ray-traced with PlotOptiX, natural colors"></img><br/><br/>
                            <img src={moon_03} alt="Moon model ray-traced with PlotOptiX, natural colors"></img><br/><br/>
                            <img src={moon_04} alt="Moon model ray-traced with PlotOptiX, elevation mapped to colors"></img><br/><br/>
                            <img src={moon_05} alt="Moon model ray-traced with PlotOptiX, elevation mapped to colors"></img><br/><br/>
                            <img src={moon_06} alt="Moon model ray-traced with PlotOptiX, elevation mapped to colors"></img><br/><br/>
                            <img src={moon_07} alt="Moon model ray-traced with PlotOptiX, elevation mapped to colors"></img><br/><br/>
                        </p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <div className="infotext">
                        <p>You are welcome to visit projects on <a href="https://www.behance.net/rndteam" target="_blanc" rel="noopener noreferrer">Behance profile.</a></p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div>

                <Footer />

            </div>
        )
}

export default MoonProject;