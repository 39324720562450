import React from 'react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import vibration from '../images/posters/vibration_mockup.jpg'
import illusion from '../images/posters/illusion_mockup.jpg'
import entropy from '../images/posters/entropy_mockup.jpg'

import thumb from '../images/posters/vibration_v1_1.jpg'

import Footer from '../components/Footer/Footer';

import { useNavigate } from 'react-router-dom';

const PostersProject = () => {
    const navigate = useNavigate();
        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop smooth />


                <div className="beginning"> 
                    <p className="title">Posters</p>   
                </div>

                <div className="infoframe">
                    <div className="infotext">           
                        <p>
                            We work also on traditional mediums, designing advertising billboards, posters for your home and office space, and graphics for printing.
                        </p>          
                        <hr/>   
                        <p>
                            The topic for the series of posters below was to transform typography for the meaning of the catchword. Posters in this limited edition
                            (10 pcs of each poster, originally numbered and signed) are printed on the high quality artistic paper, in 70x50cm, 300dpi size. Available
                            for purchase on <a href="mailto:contact@rnd.team?subject=query for poster">request</a>. 
                        </p>
                    </div>
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p><i>Vibration</i> &nbsp;<small>(each poster copy has a unique content configuration)</small></p>
                        <p>
                            <img src={vibration} alt="typographic poster: vibration"></img><br/>
                        </p>
                    </div>
                </div>

                <div className="infoframe"> 
                        <p><video autoPlay muted loop poster={thumb} src="https://rnd.team/mp4/vibration_v1_1.mp4" type="video/mp4" ></video></p>
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p><i>Illusion</i> &nbsp;</p>
                        <p>
                            <img src={illusion} alt="typographic poster: illusion"></img><br/>
                        </p>
                    </div>
                </div> 

                <div className="infoframe">
                    <div className="infotext">
                        <p><i>Entropy</i> &nbsp;<small>(each poster copy has a unique content configuration)</small></p>
                        <p>    
                            <img src={entropy} alt="typographic poster: illusion"></img><br/>
                        </p>          
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div>

                <Footer />

            </div>
        )
}

export default PostersProject;