import React from 'react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import noise_01 from '../images/noise/simplex_sculpture_1.jpg';
import noise_02 from '../images/noise/simplex_sculpture_2.jpg';
import noise_03 from '../images/noise/simplex_sculpture_3.jpg';
import noise_04 from '../images/noise/simplex_sculpture_4.jpg';
import noise_05 from '../images/noise/simplex_abstract_1.jpg';
import noise_06 from '../images/noise/simplex_abstract_2.jpg';
import noise_07 from '../images/noise/simplex_abstract_3.jpg';
import Footer from '../components/Footer/Footer';

import { Link, useNavigate } from 'react-router-dom';

const NoiseProject = () => {
    const navigate = useNavigate();
        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop smooth />

                <div className="beginning"> 
                    <p className="title">Noise Sculptures</p>  
                </div>

                <div className="embed-container">
                    <iframe title="Magnetic" src="https://player.vimeo.com/video/433180418"></iframe>
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            Collection of imaginary sculptures created as a result of our exploration of noise generation and force fields algorithms.
                            Animations and images are prepared and ray-traced in Python with <Link to="/project/plotoptix">PlotOptiX</Link>. Basic code ideas
                            are available on <a href="https://github.com/rnd-team-dev/plotoptix/tree/master/examples/3_projects/simplex" target="_blanc">GitHub</a>.
                            <br/><br/>
                            <Link to="/contact">Contact us</Link> for the signed high-quality posters printed on the artistic paper.
                            <br/><br/>
                            Printable, high resolution, unwatermarked images are <a href="https://www.patreon.com/rndteam?fan_landing=true" target="_blanc">available for Patrons</a>.
                            <br/><br/>
                            Here we share some of the visual results.<br/>
                        </p>

                        <p>
                            <img src={noise_01} alt="Noise sculpture ray-traced with PlotOptiX"></img><br/><br/>
                            <img src={noise_02} alt="Noise sculpture ray-traced with PlotOptiX"></img><br/><br/>
                            <img src={noise_03} alt="Noise sculpture ray-traced with PlotOptiX"></img><br/><br/>
                            <img src={noise_04} alt="Noise sculpture ray-traced with PlotOptiX"></img><br/><br/>
                        </p>

                        <p>
                            The work on sculptures presented in a gallery environment was preceded by experiments with abstract compositions, few of them
                            are included below.<br/>
                        </p>
                        
                        <p>
                            <img src={noise_05} alt="Noise sculpture ray-traced with PlotOptiX"></img><br/><br/>
                            <img src={noise_06} alt="Noise sculpture ray-traced with PlotOptiX"></img><br/><br/>
                            <img src={noise_07} alt="Noise sculpture ray-traced with PlotOptiX"></img><br/><br/>
                        </p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <div className="infotext">
                        <p>You are welcome to visit projects on <a href="https://www.behance.net/rndteam" target="_blanc" rel="noopener noreferrer">Behance profile</a>.</p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div>

                <Footer />

            </div>
        )
}

export default NoiseProject;