import React from 'react'
import './Videosection.css'

import poster_img from '../../images/poster_1.jpg';

class Videosection extends React.Component {
/*
    constructor() {
        super();
        this.state = {
          sections: [
            "https://rnd.team/mp4/insta_backup_1.mp4",
            "https://rnd.team/mp4/insta_backup_2.mp4",
            "https://rnd.team/mp4/insta_backup_3.mp4",
            "https://rnd.team/mp4/insta_backup_4.mp4",
            "https://rnd.team/mp4/insta_backup_5.mp4",
            "https://rnd.team/mp4/insta_backup_6.mp4",
            "https://rnd.team/mp4/insta_backup_7.mp4",
            "https://rnd.team/mp4/insta_backup_8.mp4",
            "https://rnd.team/mp4/insta_backup_9.mp4"
          ],
          size: 9
        };
      }

      componentDidMount() {
        const position = Math.floor(Math.random() * this.state.size);
        this.videoNode.src = this.state.sections[position];
        //console.log(position);
      }
*/
    componentDidMount() {
        var hlsConfig = {
            autoStartLoad: true,
            debug: false,
            maxBufferLength: 200,
            liveSyncDurationCount: 3,
            maxMaxBufferLength: 200,
            startFragPrefetch: false,
            xhrSetup: function(xhr, url) { xhr.withCredentials = false; }
        }
        
        this.player = new window.Hls(hlsConfig);
        this.player.startLevel = 0;

        this.player.attachMedia(this.videoNode);
        //this.player.loadSource("https://live1.rnd.team/hls/test/index.m3u8");
        this.player.loadSource("https://rnd.team/mp4/filepieces/720p.m3u8");

        //console.log(this.player);
      }
    
    componentWillUnmount() {
        this.videoNode.pause();
      }

    render() {
        return (
            <div className="container-video" id = {this.props.id}>
                {/* <video autoPlay muted loop src={this.props.videofile} type="video/mp4" /> */}

                {/* <video ref={ node => this.videoNode = node } autoPlay muted loop src="https://rnd.team/mp4/insta_backup_1.mp4" type="video/mp4" poster={poster_img} ></video> */}

                <video ref={ node => this.videoNode = node } autoPlay muted loop poster={poster_img}></video>

                {/* <video data-dashjs-player autoPlay muted src="http://134.209.235.151/dash/test/index.mpd"></video> */} 

                {/* <video autoPlay muted loop src="https://test.rnd.team/mp4/surface_anim.mp4" type="video/mp4" /> */}
                {/* <video data-dashjs-player autoPlay muted src="https://dash.akamaized.net/envivio/EnvivioDash3/manifest.mpd" /> */}
            </div>

        );
    }

    destroy() {
        this.player.reset();
        super.dispose();
      }
}


export default Videosection;