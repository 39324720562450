import React from 'react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import pose_heatmap from '../images/pose/pose_heatmap.jpg';

import Footer from '../components/Footer/Footer';

import { useNavigate } from 'react-router-dom';

const PoseProject = () => {
    const navigate = useNavigate();
        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop smooth />

                <div className="beginning"> 
                    <p className="title">Human Pose Reconstruction</p>  
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            <img src={pose_heatmap} alt="Human pose reconstruction, elbows heatmap"></img><br/><br/>
                        </p>
                        <p>
                            Reconstruction of the human pose is a trendy ML topic these days, yet popular solutions are not really <i>real-time</i> and
                            often the underlying model is not open to the user. We equipped Świerk Computer Centre
                            in our custom ML model for the pose reconstruction and a set of tools for the multi-GPU training of the model.
                            The inference mode was integrated into automatic annotation tool of our project partner.<br />
                            The whole package has several novel solutions not present in literature, and the processing time we obtained
                            on 4xK80 server is up to 70 poses / second!
                        </p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div>

                <Footer />

            </div>
        )
}

export default PoseProject;