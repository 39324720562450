import React from 'react'
import * as THREE from 'three';

import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
// import { OrbitControls } from 'three/addons/controls/OrbitControls.js'

import pathtoglb from '../../glbs/avatar_talking.glb';
import t_black_refl from './textures/black_refl.jpg';
import t_studio_refl from './textures/studio_refl.jpg';
import t_head_d from './textures/head_d.jpg';
import t_head_n from './textures/head_n.jpg';
import t_head_r from './textures/head_r.jpg';
import t_ao_head from './textures/ao_head.jpg';
import t_eyelash from './textures/eyelash.png';
import t_eye_d from './textures/eye_d.jpg';
import t_eye_n from './textures/eye_n.jpg';
import t_eye_r from './textures/eye_r.png';
import t_tongue_d from './textures/tongue_d.jpg';
import t_tongue_n from './textures/tongue_n.jpg';
import t_jaw_upper_shadow_d from './textures/jaw_upper_shadow_d.jpg';
import t_jaw_upper_n from './textures/jaw_upper_n.jpg';
import t_jaw_lower_shadow_d from './textures/jaw_lower_shadow_d.jpg';
import t_jaw_lower_n from './textures/jaw_lower_n.jpg';
import t_hair_d from './textures/hair_d.png';
import t_hair_n from './textures/hair_n.jpg';

import './WebGLAvatar3D.css'

const fps = 30;

class WebGLAvatar3D extends React.Component {
    constructor(props)
    {
        super(props);
        this.windowResize = this.windowResize.bind(this);
    }

    sceneInit() 
    {   
        const width = window.innerWidth;
        const height = window.innerHeight;

        this.renderer = new THREE.WebGLRenderer({ antialias: true, powerPreference: "high-performance" });
        this.renderer.setPixelRatio(window.setPixelRatio);
        this.renderer.setSize(width, height);
        this.renderer.setClearColor(0x150602);
        this.renderer.autoClear = false;
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

        
        
        this.mount.appendChild( this.renderer.domElement );

        var scene = new THREE.Scene();

        var cam_ar = width / height;

        var cameraLookAt = new THREE.Vector3(0, 1.5, 0);
        
        this.camera = new THREE.PerspectiveCamera( 60, cam_ar, 0.1, 1000 );
        //this.camera = new THREE.PerspectiveCamera( this.getCameraFov(width), cam_ar, 0.2, 800 );
        this.camera.position.set(0, 1.48, 0.62);
        this.camera.lookAt(cameraLookAt.x, cameraLookAt.y, cameraLookAt.z);
        this.camera.updateProjectionMatrix();
        scene.add(this.camera);

/*         this.controls = new OrbitControls( this.camera, this.renderer.domElement );
        this.controls.target.set(cameraLookAt.x, cameraLookAt.y, cameraLookAt.z);
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;
        this.controls.update(); */

        var ambient = new THREE.AmbientLight( 0xffffff , 0.1 );
        scene.add( ambient );

        this.light_front = new THREE.PointLight(0xFFFFFF, 7);
        this.light_front.position.set( 0.3, 1.7, 2 );
        scene.add( this.light_front);

        this.light_side = new THREE.PointLight(0xFFFFFF, 1);
        this.light_side.position.set( 0.12, 0.75, 0.05 );
        scene.add( this.light_side);

        const renderPass = new RenderPass( scene, this.camera );

        this.composer = new EffectComposer(this.renderer);
        this.composer.addPass(renderPass);

        this.textureLoader = new THREE.TextureLoader();
        this.textureEquirecW = this.textureLoader.load(t_black_refl);
        this.textureEquirecW.mapping = THREE.EquirectangularReflectionMapping;
        this.textureEquirecW.magFilter = THREE.LinearFilter;
        this.textureEquirecW.minFilter = THREE.LinearMipmapLinearFilter;
        this.textureEquirecW.colorSpace = THREE.LinearSRGBColorSpace;
        this.textureEquirecW.wrapS = THREE.MirroredRepeatWrapping;
        this.textureEquirecW.wrapT = THREE.MirroredRepeatWrapping;

        this.textureEquirecD = this.textureLoader.load(t_studio_refl);
        this.textureEquirecD.mapping = THREE.EquirectangularReflectionMapping;
        this.textureEquirecD.magFilter = THREE.LinearFilter;
        this.textureEquirecD.minFilter = THREE.LinearMipmapLinearFilter;
        this.textureEquirecD.colorSpace = THREE.LinearSRGBColorSpace;
        this.textureEquirecD.wrapS = THREE.MirroredRepeatWrapping;
        this.textureEquirecD.wrapT = THREE.MirroredRepeatWrapping;

        var whiteMatMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xFBFBFB,
            roughness: 0.5,
            metalness: 0,
            reflectivity: 0.1,
            envMap: this.textureEquirecW,
            side: THREE.DoubleSide
          });

          this.textureHeadC = this.textureLoader.load(t_head_d);
          this.textureHeadC.mapping = THREE.UVMapping;
          this.textureHeadC.magFilter = THREE.LinearFilter;
          this.textureHeadC.minFilter = THREE.LinearFilter;
          this.textureHeadC.colorSpace = THREE.SRGBColorSpace;
          this.textureHeadC.wrapS = THREE.RepeatWrapping;
          this.textureHeadC.wrapT = THREE.RepeatWrapping;
  
          this.textureHeadN = this.textureLoader.load(t_head_n);
          this.textureHeadN.mapping = THREE.UVMapping;
          this.textureHeadN.magFilter = THREE.LinearFilter;
          this.textureHeadN.minFilter = THREE.LinearFilter;
          this.textureHeadN.colorSpace = THREE.LinearSRGBColorSpace;
          this.textureHeadN.wrapS = THREE.RepeatWrapping;
          this.textureHeadN.wrapT = THREE.RepeatWrapping;
  
          this.textureHeadR = this.textureLoader.load(t_head_r);
          this.textureHeadR.mapping = THREE.UVMapping;
          this.textureHeadR.magFilter = THREE.LinearFilter;
          this.textureHeadR.minFilter = THREE.LinearFilter;
          this.textureHeadR.colorSpace = THREE.LinearSRGBColorSpace;
          this.textureHeadR.wrapS = THREE.RepeatWrapping;
          this.textureHeadR.wrapT = THREE.RepeatWrapping;
  
          this.textureHeadAO = this.textureLoader.load(t_ao_head);
          this.textureHeadAO.mapping = THREE.UVMapping;
          this.textureHeadAO.magFilter = THREE.LinearFilter;
          this.textureHeadAO.minFilter = THREE.LinearFilter;
          this.textureHeadAO.colorSpace = THREE.SRGBColorSpace;

          var headMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xffffff,
            map: this.textureHeadC,
            normalMap: this.textureHeadN,
            normalScale: new THREE.Vector2(0.5, 0.5),
            roughness: 1,
            roughnessMap: this.textureHeadR,
            metalness: 0,
            reflectivity: 0.3,
            aoMap: this.textureHeadAO,
            aoMapIntensity: 1.5,
            envMap: this.textureEquirecW,
            side: THREE.DoubleSide
          });

          this.textureEyelashC = this.textureLoader.load(t_eyelash);
          this.textureEyelashC.mapping = THREE.UVMapping;
          this.textureEyelashC.magFilter = THREE.LinearFilter;
          this.textureEyelashC.minFilter = THREE.LinearFilter;
          this.textureEyelashC.colorSpace = THREE.SRGBColorSpace;
          this.textureEyelashC.wrapS = THREE.RepeatWrapping;
          this.textureEyelashC.wrapT = THREE.RepeatWrapping;

          var eyelashMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xffffff,
            map: this.textureEyelashC,
            roughness: 0.4,
            metalness: 0,
            reflectivity: 0.15,
            envMap: this.textureEquirecW,
            side: THREE.DoubleSide,
            depthWrite: false,
            transparent: true
          });
  
          var lacrimaMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xcccccc,
            roughness: 0.2,
            metalness: 1.0,
            reflectivity: 0.9,
            envMap: this.textureEquirecW,
            opacity: 0.05,
            transparent: true
          });
  
          var comeaMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xcccccc,
            roughness: 0.2,
            metalness: 1.0,
            reflectivity: 0.9,
            envMap: this.textureEquirecW,
            opacity: 0.1,
            transparent: true
          });

          this.texturePupilC = this.textureLoader.load(t_eye_d);
          this.texturePupilC.mapping = THREE.UVMapping;
          this.texturePupilC.magFilter = THREE.LinearFilter;
          this.texturePupilC.minFilter = THREE.LinearFilter;
          this.texturePupilC.colorSpace = THREE.SRGBColorSpace;
          this.texturePupilC.wrapS = THREE.RepeatWrapping;
          this.texturePupilC.wrapT = THREE.RepeatWrapping;
  
          this.texturePupilN = this.textureLoader.load(t_eye_n);
          this.texturePupilN.mapping = THREE.UVMapping;
          this.texturePupilN.magFilter = THREE.LinearFilter;
          this.texturePupilN.minFilter = THREE.LinearFilter;
          this.texturePupilN.colorSpace = THREE.LinearSRGBColorSpace;
          this.texturePupilN.wrapS = THREE.RepeatWrapping;
          this.texturePupilN.wrapT = THREE.RepeatWrapping;
  
          this.texturePupilR = this.textureLoader.load(t_eye_r);
          this.texturePupilR.mapping = THREE.UVMapping;
          this.texturePupilR.magFilter = THREE.LinearFilter;
          this.texturePupilR.minFilter = THREE.LinearFilter;
          this.texturePupilR.colorSpace = THREE.LinearSRGBColorSpace;
          this.texturePupilR.wrapS = THREE.RepeatWrapping;
          this.texturePupilR.wrapT = THREE.RepeatWrapping;

          var pupilMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xffffff,
            map: this.texturePupilC,
            normalMap: this.texturePupilN,
            normalScale: new THREE.Vector2(0.99, 0.99),
            roughnessMap: this.texturePupilR,
            metalness: 0,
            reflectivity: 0.3,
            envMap: this.textureEquirecW
          });
  
          this.textureTongueC = this.textureLoader.load(t_tongue_d);
          this.textureTongueC.mapping = THREE.UVMapping;
          this.textureTongueC.magFilter = THREE.LinearFilter;
          this.textureTongueC.minFilter = THREE.LinearMipmapLinearFilter;
          this.textureTongueC.colorSpace = THREE.SRGBColorSpace;
          this.textureTongueC.wrapS = THREE.RepeatWrapping;
          this.textureTongueC.wrapT = THREE.RepeatWrapping;
  
          this.textureTongueN = this.textureLoader.load(t_tongue_n);
          this.textureTongueN.mapping = THREE.UVMapping;
          this.textureTongueN.magFilter = THREE.LinearFilter;
          this.textureTongueN.minFilter = THREE.LinearMipmapLinearFilter;
          this.textureTongueN.colorSpace = THREE.LinearSRGBColorSpace;
          this.textureTongueN.wrapS = THREE.RepeatWrapping;
          this.textureTongueN.wrapT = THREE.RepeatWrapping;

          var tongueMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xffffff,
            map: this.textureTongueC,
            normalMap: this.textureTongueN,
            normalScale: new THREE.Vector2(0.99, 0.99),
            roughness: 0.1,
            metalness: 0,
            reflectivity: 0.15,
            envMap: this.textureEquirecD,
            envMapIntensity: 0.3,
            side: THREE.DoubleSide,
          });
  
          this.textureJawUpperC = this.textureLoader.load(t_jaw_upper_shadow_d);
          this.textureJawUpperC.mapping = THREE.UVMapping;
          this.textureJawUpperC.magFilter = THREE.LinearFilter;
          this.textureJawUpperC.minFilter = THREE.LinearMipmapLinearFilter;
          this.textureJawUpperC.colorSpace = THREE.SRGBColorSpace;
          this.textureJawUpperC.wrapS = THREE.RepeatWrapping;
          this.textureJawUpperC.wrapT = THREE.RepeatWrapping;
  
          this.textureJawUpperN = this.textureLoader.load(t_jaw_upper_n);
          this.textureJawUpperN.mapping = THREE.UVMapping;
          this.textureJawUpperN.magFilter = THREE.LinearFilter;
          this.textureJawUpperN.minFilter = THREE.LinearMipmapLinearFilter;
          this.textureJawUpperN.colorSpace = THREE.LinearSRGBColorSpace;
          this.textureJawUpperN.wrapS = THREE.RepeatWrapping;
          this.textureJawUpperN.wrapT = THREE.RepeatWrapping;

          var jawUpperMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xffffff,
            map: this.textureJawUpperC,
            normalMap: this.textureJawUpperN,
            normalScale: new THREE.Vector2(0.99, 0.99),
            roughness: 0.1,
            metalness: 0,
            reflectivity: 0.15,
            envMap: this.textureEquirecD,
            envMapIntensity: 0.3,
            side: THREE.DoubleSide,
          });
  
  
          this.textureJawLowerC = this.textureLoader.load(t_jaw_lower_shadow_d);
          this.textureJawLowerC.mapping = THREE.UVMapping;
          this.textureJawLowerC.magFilter = THREE.LinearFilter;
          this.textureJawLowerC.minFilter = THREE.LinearMipmapLinearFilter;
          this.textureJawLowerC.colorSpace = THREE.SRGBColorSpace;
          this.textureJawLowerC.wrapS = THREE.RepeatWrapping;
          this.textureJawLowerC.wrapT = THREE.RepeatWrapping;
  
          this.textureJawLowerN = this.textureLoader.load(t_jaw_lower_n);
          this.textureJawLowerN.mapping = THREE.UVMapping;
          this.textureJawLowerN.magFilter = THREE.LinearFilter;
          this.textureJawLowerN.minFilter = THREE.LinearMipmapLinearFilter;
          this.textureJawLowerN.colorSpace = THREE.LinearSRGBColorSpace;
          this.textureJawLowerN.wrapS = THREE.RepeatWrapping;
          this.textureJawLowerN.wrapT = THREE.RepeatWrapping;

          var jawLowerMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xffffff,
            map: this.textureJawLowerC,
            normalMap: this.textureJawLowerN,
            normalScale: new THREE.Vector2(0.99, 0.99),
            roughness: 0.1,
            metalness: 0,
            reflectivity: 0.15,
            envMap: this.textureEquirecD,
            envMapIntensity: 0.3,
            side: THREE.DoubleSide,
          });
  
          this.textureHairC = this.textureLoader.load(t_hair_d);
          this.textureHairC.mapping = THREE.UVMapping;
          this.textureHairC.magFilter = THREE.LinearFilter;
          this.textureHairC.minFilter = THREE.LinearMipmapLinearFilter;
          this.textureHairC.colorSpace = THREE.SRGBColorSpace;
          this.textureHairC.wrapS = THREE.RepeatWrapping;
          this.textureHairC.wrapT = THREE.RepeatWrapping;
  
          this.textureHairN = this.textureLoader.load(t_hair_n);
          this.textureHairN.mapping = THREE.UVMapping;
          this.textureHairN.magFilter = THREE.LinearFilter;
          this.textureHairN.minFilter = THREE.LinearMipmapLinearFilter;
          this.textureHairN.colorSpace = THREE.LinearSRGBColorSpace;
          this.textureHairN.wrapS = THREE.RepeatWrapping;
          this.textureHairN.wrapT = THREE.RepeatWrapping;

          var hairMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xa56525,
            map: this.textureHairC,
            normalMap: this.textureHairN,
            normalScale: new THREE.Vector2(0.99, 0.99),
            //specularIntensity: 0.3,
            roughness: 0.1,
            //sheen: 0.3,
            metalness: 0,
            reflectivity: 0.15,
            envMap: this.textureEquirecD,
            envMapIntensity: 0.3,
            side: THREE.DoubleSide,
          });
  
          var hairTrMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xa56525,
            map: this.textureHairC,
            normalMap: this.textureHairN,
            normalScale: new THREE.Vector2(0.7, 0.7),
            specularIntensity: 0.3,
            roughness: 0.4,
            sheen: 0.3,
            metalness: 0,
            reflectivity: 0.1,
            envMap: this.textureEquirecD,
            envMapIntensity: 0.3,
            side: THREE.DoubleSide,
            depthWrite: false,
            transparent: true
          });

          var bikiniMaterial = new THREE.MeshPhysicalMaterial({
            color: 0xff000a,
            roughness: 0.3,
            metalness: 0.0,
            reflectivity: 0.2,
            envMap: this.textureEquirecW
          });

          this.morphTargets = {};
          this.morphingObjs = [];
  
          this.model = undefined;
  
          this.mixer = undefined;
          this.action = undefined;
  
          var scope = this;

          function loadModel(url) {
            return new Promise(resolve => {
              new GLTFLoader().load(url, resolve);
            });
          }

          function setMaterials(child, scope) {

            if ((child.morphTargetDictionary !== undefined) && (Object.keys(child.morphTargetDictionary).length > 0)) {
                for (var name in child.morphTargetDictionary) {
                    scope.morphTargets[name] = [0.0, 0.0];
                }
                scope.morphingObjs.push(child);
            }

            if ((child.name === 'head') && (child.type === 'SkinnedMesh')) {
                console.log(child.name);
                child.material = headMaterial;
                scope.model = child;
            }
            else if ((child.name === 'eyelash') && (child.type === 'Mesh')) {
                console.log(child.name);
                child.material = eyelashMaterial;
            }
            else if ((child.name === 'pupil') && (child.type === 'Mesh')) {
                console.log(child.name);
                child.material = pupilMaterial;
            }
            else if ((child.name === 'lacrima') && (child.type === 'Mesh')) {
                console.log(child.name);
                child.material = lacrimaMaterial;
            }
            else if ((child.name === 'comea') && (child.type === 'Mesh')) {
                console.log(child.name);
                child.material = comeaMaterial;
            }
            else if ((child.name === 'bikini') && (child.type === 'Mesh')) {
                console.log(child.name);
                child.material = bikiniMaterial;
            }
            else if ((child.name === 'opaque') && (child.type === 'Mesh')) {
                console.log(child.name);
                child.material = hairMaterial;
            }
            else if ((child.name === 'hashed') && (child.type === 'Mesh')) {
                console.log(child.name);
                child.material = hairTrMaterial;
            }
            else if ((child.name === 'tongue') && (child.type === 'Mesh')) {
              console.log(child.name);
              child.material = tongueMaterial;
            }
            else if ((child.name === 'gumdown') && (child.type === 'Mesh')) {
              console.log(child.name);
              child.material = jawLowerMaterial;
            }
            else if ((child.name === 'gumup') && (child.type === 'Mesh')) {
              console.log(child.name);
              child.material = jawUpperMaterial;
            }
            else if ((child.name === 'teethdown') && (child.type === 'Mesh')) {
              console.log(child.name);
              child.material = jawLowerMaterial;
            }
            else if ((child.name === 'teethup') && (child.type === 'Mesh')) {
              console.log(child.name);
              child.material = jawUpperMaterial;
            }
            else if (child.type === 'Mesh') {
                console.log(child.name);
                child.material = whiteMatMaterial;
            }

            for (var i = 0; i < child.children.length; i++) {
                setMaterials(child.children[i], scope);
            }
        }

        const loader = new GLTFLoader();
        loader.load(
            pathtoglb, 
            function (gltf) 
        {  
            const clip = THREE.AnimationClip.findByName( gltf.animations, 'SkeletonAction' );
  
            scope.mixer = new THREE.AnimationMixer(gltf.scene);
            scope.action = scope.mixer.clipAction(clip);
  
  
            gltf.scene.scale.set(1, 1, 1);
            gltf.scene.position.set(0, 0, 0);
            gltf.scene.rotation.set(0, 0, 0);
  
            for (var i = 0; i < gltf.scene.children.length; i++) {
                var child = gltf.scene.children[i];
  
                setMaterials(child, scope);
            }
  
            scene.add(gltf.scene);
          } );
  
        this.setMorphTo = function (name, val, speed) {
  
          if (!(name in this.morphTargets)) { return; }
  
          var x = 0.0;
          for (var i = 0; i < this.morphingObjs.length; i++) {
              const obj = this.morphingObjs[i];
              if (name in obj.morphTargetDictionary) {
                  const id = obj.morphTargetDictionary[name];
                  x = obj.morphTargetInfluences[id];
                  break;
              }
          }
          
          var d = val - x;
          if (d * d > 0.0001) {
            d *= speed * this.lastFrameDt;
            for (var i = 0; i < this.morphingObjs.length; i++) {
              const obj = this.morphingObjs[i];
              if (name in obj.morphTargetDictionary) {
                const id = obj.morphTargetDictionary[name];
                obj.morphTargetInfluences[id] = x + d;
              }
            }
          }
        }

        this.closeEyes = function (scope) {
            scope.morphTargets["eyeBlinkLeft"] = [0.99, 0.2];
            scope.morphTargets["eyeBlinkRight"] = [0.99, 0.2];
          }
          this.openEyes = function (scope) {
            scope.morphTargets["eyeBlinkLeft"] = [0.0, 0.2];
            scope.morphTargets["eyeBlinkRight"] = [0.0, 0.2];
          }
    
          this.blink = function () {
            this.closeEyes(this);
            setTimeout(this.openEyes, 200, this);
          }

          this.browMove = function (scope) {
            if (scope.isTalking) {
              var nextVal = ("browInnerUp" in scope.morphTargets) ? scope.morphTargets["browInnerUp"][0] : 0.0;
              nextVal += Math.random() - 0.5; // no extreme changes
              nextVal = Math.max(0.0, Math.min(1.0, nextVal));
    
              var nextSpeed = 0.01 + 0.07 * Math.random();
              scope.morphTargets["browInnerUp"] = [nextVal, nextSpeed];
    
              nextVal = ("browOuterUpLeft" in scope.morphTargets) ? scope.morphTargets["browOuterUpLeft"][0] : 0.0;
              nextVal += Math.random() - 0.5; // no extreme changes
              nextVal = Math.max(0.0, Math.min(1.0, nextVal));
    
              nextSpeed = 0.01 + 0.05 * Math.random();
              scope.morphTargets["browOuterUpLeft"] = [nextVal, nextSpeed];
              scope.morphTargets["browOuterUpRight"] = [nextVal, nextSpeed];
    
              const nextDelay = 500 + 2000 * Math.random();
              setTimeout(scope.browMove, nextDelay, scope);
            }
          }

          this.browStop = function () {
            scope.morphTargets["browInnerUp"] = [0.0, 0.05];
            scope.morphTargets["browOuterUpLeft"] = [0.0, 0.04];
            scope.morphTargets["browOuterUpRight"] = [0.0, 0.04];
          }
    
          this.jawOpen = false;
          this.jawMove = function (scope) {
            if (scope.isTalking) {
              scope.jawOpen = !scope.jawOpen;
              var nextVal = scope.jawOpen ? 0.28 * Math.random() : 0.0; // flip open/close
              var nextSpeed = 0.03 + 0.07 * Math.random();
              scope.morphTargets["jawOpen"] = [nextVal, nextSpeed];
    
              nextVal = ("mouthShrugUpper" in scope.morphTargets) ? scope.morphTargets["mouthShrugUpper"][0] : 0.0;
              nextVal += 0.35 * (2 * Math.random() - 1);
              nextVal = Math.max(0.0, Math.min(0.6, nextVal));
                        
              nextSpeed = 0.02 + 0.05 * Math.random();
              scope.morphTargets["mouthShrugUpper"] = [nextVal, nextSpeed];
    
              nextVal *= 0.6
              nextVal = Math.max(0.0, Math.min(0.25, nextVal));
              scope.morphTargets["mouthSmileLeft"] = [nextVal, nextSpeed];
              scope.morphTargets["mouthSmileRight"] = [nextVal, nextSpeed];
    
              nextVal = scope.jawOpen ? 0.1 * (2 * Math.random() - 1) : 0.0;
              scope.morphTargets["jawLeft"] = [(nextVal > 0) ? nextVal : 0.0, nextSpeed];
              scope.morphTargets["jawRight"] = [(nextVal < 0) ? nextVal : 0.0, nextSpeed];
    
              nextVal = 0.1 * Math.random();
              nextSpeed = 0.02 + 0.05 * Math.random();
              scope.morphTargets["cheekPuff"] = [nextVal, nextSpeed];
    
              const nextDelay = 100 + 150 * Math.random();
              setTimeout(scope.jawMove, nextDelay, scope);
            }
          }
          this.jawStop = function () {
            scope.morphTargets["jawOpen"] = [0.0, 0.15];
            scope.morphTargets["jawLeft"] = [0.0, 0.1];
            scope.morphTargets["jawRight"] = [0.0, 0.1];
            scope.morphTargets["mouthShrugUpper"] = [0.0, 0.05];
            scope.morphTargets["mouthSmileLeft"] = [0.15, 0.05];
            scope.morphTargets["mouthSmileRight"] = [0.15, 0.05];
            scope.morphTargets["cheekPuff"] = [0.0, 0.05];
            this.jawOpen = false;
          }
    
          this.isTalking = false;
          this.startTalking = function () {
            console.log('startTalking');
            this.isTalking = true;
    
            this.browMove(this);
            this.jawMove(this);
          }
          this.stopTalking = function () {
            console.log('stopTalking');
            this.isTalking = false;
    
            this.browStop();
            this.jawStop();
          }

          this.play = function () {
            if (this.action !== undefined) {
                console.log('start action');
                this.action.play();
            }
            else {
                console.log('action not ready');
            }
          }

          this.randomChangeState = function () {
            if (Math.random() < 0.6) {
              this.blink();
            }

            if (this.isTalking && Math.random() < 0.2) {
              this.stopTalking();
            }
            else if (!this.isTalking) {
              this.startTalking();
            }
          }

          this.lastFrameDt = 1.0;
          this.lastFrameMs = 0;
          this.frameCount = 0;
          this.updateModel = function () {
    
            var msNow = new Date().getTime();
            this.lastFrameDt = Math.min(msNow - this.lastFrameMs, 150) / 17.0;
            this.lastFrameMs = msNow;
    
            if ( this.mixer !== undefined ) {
                this.mixer.update( 1.0 / fps );
            }
    
            if ( this.model !== undefined ) {
              for (var name in this.morphTargets) {
                this.setMorphTo(name, this.morphTargets[name][0], this.morphTargets[name][1]);
              }
            }

            this.frameCount++;
            if (this.frameCount > 1.5 * fps) {
              this.randomChangeState(this);
              this.frameCount = 0;
            }
          }
    }


    wndWidth = 0;
    wndHeight = 0;
    windowResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      console.log(width + " " + height);
  
      const cam_ar = width / height;
  
      this.camera.aspect = cam_ar;
      this.camera.updateProjectionMatrix();
  
      this.renderer.setSize(width, height);
      this.renderer.setPixelRatio(window.devicePixelRatio);

      this.composer.setSize(width, height);
  
      this.wndWidth = width;
      this.wndHeight = height;
    }

    onAnimate = undefined;
    
    animate() {

      if (this.animationThrottleId) {
        clearTimeout(this.animationThrottleId)
      }

      this.animationThrottleId = setTimeout(() => {
        this.animationId = requestAnimationFrame(this.animate.bind(this))
      }, 1000 / fps)
  
      if (this.onAnimate !== undefined) {
        this.onAnimate();
      }

      this.updateModel();
    
      this.composer.render();
    }

    componentDidMount() 
    {
      this.sceneInit();

      window.addEventListener("resize", this.windowResize, false);

      this.animate();
    }



    render() 
    {
        return( <div ref={ref => (this.mount = ref)} className='webglavatar3d' />  )
    }
}

export default WebGLAvatar3D;