import React from 'react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import instaproj2 from '../images/instaproject_2.jpg';
import { ReactComponent as Illustr1 } from '../images/icons/vector_illustration_v2.svg';

import Footer from '../components/Footer/Footer';

import { useNavigate } from 'react-router-dom';


const InstaProject = () => {
    const navigate = useNavigate()
       
        return (
            
            <div>

                <ScrollToTopOnMount />
                <ScrollToTop smooth />

                <div className="beginning"> 
                    <p className="title">Scraping Instagram </p>      
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            This project was our home page video for quite a long time. The aim of the visualization was just to show the
                            online activity in <b>real-time</b>, without any particular analysis goal. However, targeted and informative animations
                            of this kind have great potential as web graphics or in the real-life interiors, displayed on big screens.<br/>
                        </p>
                    </div>
                </div>

                <div className="fullscreen">
                        <video autoPlay muted loop src="https://rnd.team/mp4/insta_backup_1.mp4" type="video/mp4"></video>
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            <span style={{color: "#BBB"}}>
                            Data in this animation was scraped from Instagram. It is a good example of real-time evolving data, with lots of dependencies
                            and complex relations to discover. Here we were watching post tagged with #art, #exhibition, #museum, #installation, and a few
                            other similar hashtags. Each bubble appears for one post, with the size representing the number of likes, flashing in blue when
                            it gets liked and in yellow when it receives a new comment. Bubbles are floating around according to the rules which include
                            the post's age, number of hashtags it is tagged with, and other properties.<br /><br/>
                            
                            <Illustr1 />

                            Technically: the data is scraped from Instagram and processed on our servers. The resulting summary is steering objects in
                            the animation, which is ray-traced and streamed to a client browser. The latency from a new like or comment on the post to
                            the flashing bubble on the screen is about 2-3 seconds after the browser gets synchronized.
                            </span>
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <img src={instaproj2} alt="Instagram data visualization"></img>
                        </p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div>

                <Footer />

            </div>
        )
}

export default InstaProject;