import React from "react";

import ScrollToTopOnMount from './components/ScrollToTopOnMount';
import ScrollToTop from "react-scroll-to-top";

import Footer from './components/Footer/Footer';

import rs from './images/studio_rs.jpg';
import ds from './images/studio_ds.jpg';

const About = () => {
        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop smooth />

                <div className="beginning">
                    <p className="title">A bit of history</p>
                </div> 

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            RnD Team Design Studio was founded by two scientists who decided to switch to creative life after the career in particle physics.<br />
                        </p>
                    </div>
                </div>

                <div className="row-wrapper">
                    <div className="pcolumn">
                        <img src={rs} alt="Robert, data scientist"></img>
                        <p>
                            <b>Robert Sulej</b><br/>
                            <span style={{fontSize:"small"}}><p>
                            Data scientist, <br />
                            ML and 3D code developer at RnD Team.<br/>
                            </p>
                            Google Breakthrough Prize in Fundamental Physics 2016.<br/>
                            Ph.D. in electronics at WUT, Warsaw, Poland.<br/>
                            <a href="https://www.researchgate.net/profile/Robert_Sulej" target="_blank" rel="noopener noreferrer">ResearchGate</a>,&nbsp;
                            <a href="https://www.linkedin.com/in/robert-sulej/" target="_blank" rel="noopener noreferrer">LinkedIn</a>,&nbsp;
                            <a href="https://medium.com/@sulej.robert" target="_blank" rel="noopener noreferrer">Medium</a>,&nbsp;
                            <a href="https://github.com/robertsulej" target="_blank" rel="noopener noreferrer">GitHub</a>
                            </span>
                        </p>
                    </div>
                    <div className="pcolumn">
                        <img src={ds} alt="Dorota, art director"></img>
                        <p>
                            <b>Dorota Stefan</b><br />
                            <span style={{fontSize:"small"}}>
                            <p>
                            Art director, <br />
                            2D/3D graphic designer at RnD Team.<br />
                            </p>
                            Warsaw School of Arts, with honours degree.<br />
                            Ph.D. in physics at IFJ PAN, Cracow Poland.<br />
                            <a href="https://www.researchgate.net/profile/Dorota-Stefan-2" target="_blank" rel="noopener noreferrer">ResearchGate</a>,&nbsp;
                            <a href="https://www.linkedin.com/in/dorota-stefan-b55700159/" target="_blank" rel="noopener noreferrer">LinkedIn</a>,&nbsp;
                            <a href="https://www.behance.net/dorotastefan" target="_blank" rel="noopener noreferrer">Behance</a>,&nbsp;
                            <a href="https://www.instagram.com/dorota.stefan" target="_blank" rel="noopener noreferrer">Instagram</a>
                            </span>
                        </p>
                    </div>
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        
                        <h3>Our background.</h3>
                        <p>
                            For over a decade we were both working in major physics laboratories around the world: CERN (Switzerland), Fermilab (USA), J-PARC (Japan),
                            LNGS (Italy), leading the data analysis efforts in neutrino experiments. If you are curious about this part of our lives, have a look here:&nbsp;
                            <a href="https://indico.cern.ch/event/652660/?fbclid=IwAR2-9IksuouUEMwonYFK5BYTYovh5OV2GCIMlT8yEIy4grVkBvJYyWd2BTw" target="_blank" rel="noopener noreferrer">talk on Machine Learning</a>,
                            or here: <a href="https://youtu.be/E3DKrtD48H0?list=PLLsqK861VNKBx_C9FetIlrdDr8VdXlMZX&t=656" target="_blank" rel="noopener noreferrer">TV at CERN</a>,
                            or browse for us in DUNE news, e.g. <a href="https://nus2surf.dunescience.org/2017/01/algorithms/?fbclid=IwAR2N_LRO4gjf60YPvcxYKcyDT-CFd4sSk7i3QmdzgWapQ0U46EQiZX88wPw" target="_blank" rel="noopener noreferrer">here</a>&nbsp;
                            and <a href="https://nus2surf.dunescience.org/2017/12/deep-learning-deep-underground-physics/?fbclid=IwAR2mbTmXgoyJyhdzUtBmfqaBM75eNGIVREXom6WkKrSGrKcNLdPk4ARlc_s" target="_blank" rel="noopener noreferrer">here</a>.<br/>
                            Together with <a href="https://mljar.com" target="_blank" rel="noopener noreferrer">MLJAR</a> team, we were involved in the USA energy market, designing AI systems for electricity load and day-ahead price prediction.
                        </p>
                        <h4>Where did the name RnD come from? Is it <i>research and development</i>?</h4>
                        <p>
                            Yes, and not only. We spent a long time in particle physics, always working together. We became known as Robert and Dorota, RnD.
                            We even started signing our emails as RnD so there is no doubt that both of us have the same opinion! In our present work we are constantly
                            looking for new solutions, authoring our own tools. So it is still lots of research and development.</p>
                    </div>
                </div>

                {/* <div className="row-wrapper">
                    <div className="pcolumn">
                        <b>Robert Sulej</b><br/>
                        Data scientist, ML and 3D code developer at RnD Team.<br/>
                        Google Breakthrough Prize in Fundamental Physics 2016.<br/>
                        Ph.D. in electronics at WUT, Warsaw, Poland.<br/>
                        <a href="https://www.researchgate.net/profile/Robert_Sulej" target="_blank" rel="noopener noreferrer">ResearchGate</a>,&nbsp;
                        <a href="https://www.linkedin.com/in/robert-sulej/" target="_blank" rel="noopener noreferrer">LinkedIn</a>,&nbsp;
                        <a href="https://medium.com/@sulej.robert" target="_blank" rel="noopener noreferrer">Medium</a>
                    </div>
                    <div className="pcolumn">
                        <b>Dorota Stefan</b><br />
                        Art director, graphic designer at RnD Team.<br />
                        Warsaw School of Arts.<br />
                        Ph.D. in physics at IFJ PAN, Cracow Poland.<br />
                        <a href="https://www.researchgate.net/profile/Dorota-Stefan-2" target="_blank" rel="noopener noreferrer">ResearchGate</a>,&nbsp;
                        <a href="https://www.linkedin.com/in/dorota-stefan-b55700159/" target="_blank" rel="noopener noreferrer">LinkedIn</a>,&nbsp;
                        <a href="https://www.behance.net/dorotastefan" target="_blank" rel="noopener noreferrer">Behance</a>,&nbsp;
                        <a href="https://www.instagram.com/dorota.stefan" target="_blank" rel="noopener noreferrer">Instagram</a>
                    </div>
                </div> */}

                <Footer />

            </div>
        )
}

export default About;