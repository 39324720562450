import React from 'react';
//import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client'

import { BrowserRouter as Router, Route, Routes, BrowserRouter} from 'react-router-dom';

import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
//import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));
root.render(
    //  <StrictMode>
         <BrowserRouter>
       
                <Routes>
                    <Route path="*" element={ <App /> }></Route>
                </Routes>


           
         </BrowserRouter> 
    //  </StrictMode>
);


//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

//reportWebVitals();