import React from 'react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import radio_img from '../images/radio/radio_banner.jpg';

import Footer from '../components/Footer/Footer';

import { useNavigate } from 'react-router-dom';

const RadioProject = () => {
    const navigate = useNavigate();
        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop smooth />

                <div className="beginning"> 
                    <p className="title">Field coverage in mobile communications</p>  
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            <img src={radio_img} alt="Mobile coverage visualization"></img><br/><br/>
                        </p>
                        <p>
                            City environment is a complex system for the field coverage calculation. Radio waves are reflected and diffracted
                            by buildings multiple times, and actually this complexity allows phones to communicate with cellular base station
                            rarely visible in line of sight. Modern simulation tools will do 2-3 bounces when simulating the field surrounding
                            the antenna, that gives often oversimplified results. We tailored <a href="https://plotoptix.rnd.team" target="_blanc">the PlotOptiX engine</a> to
                            propagate radio frequency rays and together with the team from the University of Alcalá (Madrid, Spain) designed
                            a tool for the field coverage calculation. It has no limits on the depth of simulated rays, and is extremely fast
                            thanks to its optimized GPU engine.<br/>
                        </p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div>

                <Footer />

            </div>
        )
}

export default RadioProject;