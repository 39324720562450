import React from 'react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import { useNavigate } from 'react-router-dom';

import WebGLAvatar3D from '../components/WebGLAvatar3D/WebGLAvatar3D';
import Footer from '../components/Footer/Footer';


const Avatar3D = () => {

    const navigate = useNavigate();

        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning"> 
                    <p className="title">Speaking 3D avatar</p>  
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            For the <a href='https://www.cognitiva.dev/'>Cognitiva</a> company we <b>adapted the 3D avatar to WebGL</b> technology, 
                            and then <b>extended it to the animated model that imitates speaking</b> for a given waveform.<br/>
                        </p>
                    </div>
                </div>

                <div className="fullscreen">
                    < WebGLAvatar3D />
                </div> 
                
                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            TOOLS: Three.js/WebGL, Blender, Faceit
                        </p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className='button' onClick={()=>navigate("/work")}> 
                        BACK
                    </button> 
                </div>

                <Footer />
            </div>
        )
}

export default Avatar3D;