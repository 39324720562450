import React from 'react';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from 'react-scroll-to-top';

import attractor_01 from '../images/attractors/attractor_d_0003.jpg';
import attractor_02 from '../images/attractors/attractor_b_0000_rnd.jpg';
import attractor_03 from '../images/attractors/attractor_b_0004_rnd.jpg';
import attractor_04 from '../images/attractors/attractor_c_0000.jpg';
import attractor_05 from '../images/attractors/attractor_c_0002_rnd.jpg';
import attractor_06 from '../images/attractors/attractor_d_0001.jpg';
import attractor_07 from '../images/attractors/attractor_e_0001.jpg';
import attractor_08 from '../images/attractors/attractor_e_0003.jpg';
import Footer from '../components/Footer/Footer';

import { Link, useNavigate } from 'react-router-dom';

const AtraktorProject = () => {
    const navigate = useNavigate();
        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop smooth />

                <div className="beginning"> 
                    <p className="title">Strange Attractors</p>  
                </div>

                <div className="infoframe">
                    <div className="infotext">
                        <p>
                            <img src={attractor_01} alt="Strange Attractor 3D ray tracing"></img><br/>

                            An <a href="https://en.wikipedia.org/wiki/Attractor" target="_blanc" rel="noopener noreferrer">attractor</a> is
                            a mathematical concept, a shape (in a multidimensional, physical or feature space) towards which a system tends
                            to evolve. That shape can have quite a complex, fractal structure - as it happens for <i>strange attractors</i>.<br/><br/>

                            We used attractors to generate data sets during our ray tracing engine development (see <Link to="/project/plotoptix">PlotOptiX</Link>).
                            It was a perfect test for large data visualizations, as each scene contains +10M primitives. Here we share a series of
                            high quality, B2-size posters which were an additional outcome to the code testing exercises. For making these images,
                            we prepared a 3D variant of <a href="http://paulbourke.net/fractals/clifford/" target="_blanc" rel="noopener noreferrer">Clifford Attractors</a> formula
                            and searched for unique combinations of coefficients, resulting with a balanced and interesting
                            composition.<br/><br/>
                        </p>
                    </div>
                </div>

                <div className="row-wrapper">
                    <div className="pcolumn">
                        <img src={attractor_02} alt="Strange Attractor poster"></img>
                        <img src={attractor_08} alt="Strange Attractor poster"></img>
                        <img src={attractor_07} alt="Strange Attractor poster"></img>
                    </div>
                    <div className="pcolumn">
                        <img src={attractor_06} alt="Strange Attractor poster"></img>
                        <img src={attractor_04} alt="Strange Attractor poster"></img>
                        <img src={attractor_05} alt="Strange Attractor poster"></img>
                        <img src={attractor_03} alt="Strange Attractor poster"></img>
                    </div>                      
                </div>

                <div className="infoframe zero_margin_top">
                    <div className="infotext">
                        <p>There are few more attractor creations on our <a href="https://www.behance.net/rndteam" target="_blanc" rel="noopener noreferrer">Behance profile.</a></p>
                    </div>
                </div>

                <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div>

                <Footer />


            </div>
        )
}

export default AtraktorProject;