import React from "react";

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import ScrollToTop from "react-scroll-to-top";  

import Footer from '../components/Footer/Footer';

import { Link, useNavigate } from 'react-router-dom';

const GenerativeContent = () => {
    const navigate = useNavigate();
        return (
            <div>
                <ScrollToTopOnMount />
                <ScrollToTop />

                <div className="beginning">
                    <p className="title">Generative content</p>
                </div>


                <div className="fullscreen">
                    <video autoPlay muted loop src="https://rnd.team/mp4/simplex_anim.mp4" type="video/mp4"></video>
                </div>

                 <div className="infoframe">
                    <div className="infotext">
                        <p>
                            Generative content design is the most unique of our services. This kind of graphics is created by a specially prepared algorithm. It keeps constant
                            the style and overall look of the graphics, but the actual content never repeats. Every time a customer returns to your website or your office, there
                            will be something new in the appearance. We develop graphics based on real data or created solely by the algorithm. An example video is spinning
                            on the <Link to="/home">home page</Link>.
                        </p>
                        <p>
                            Graphics may be inspired by the real data. It may be collected from social media, or it could be trends identified in your company assets or anything
                            you would like to associate with your brand. We apply cutting edge techniques of analysis to transform the data into the highest quality graphics.
                        </p>

                        <div>
                            Graphics may be used as:
                            <ul>
                                <li>web content: cover images and animations, content graphics</li>
                                <li>digital screens of all sizes and resolutions</li>
                            </ul>
                            Your brand will stand out among competitors!
                        </div>
                        

                        <p>
                            The range of possible implementations is wide, matching various budgets. Images may be generated daily, hourly, or it may be a real-time updated
                            content. We can host the graphics generation on our servers or, in case of large projects, build and set up a dedicated computer system for you.
                        </p>

                        <p> Our technology:<br/>

                            The algorithm for the content generation is unique for each project. We developed a framework which supports the basic graphics operations. On top
                            of this framework, a unique data collection, processing, and visualization procedures are designed. You will obtain an exclusive license to the generated
                            content or the generating algorithm as well, depending on your requirements. The software stack may be used in Linux and Windows and is based
                            on Framework.NET and Python libraries. Software extensively uses the hardware acceleration of computations and requires a modern GPU coprocessor. This means
                            that in case of the web graphics the weight of the computations lies on the side of your (our) servers and your visitors can use any device to see the
                            graphics. <Link to="/contact">Contact us</Link> if you have any questions related to the technical aspects. We will suggest a solution
                            matching the scale of your project.
                        </p>

                        <hr/>

                        <h3>PlotOptiX</h3>
                        <p>
                            <Link to="/project/plotoptix">PlotOptiX</Link> is a data ray tracing package for Python that we maintain and share with the community. It
                            is <i>free</i> for non-commercial use. It is based on tools developed for our everyday work with generative art. It allows you to create advanced,
                            photorealistic graphics straight from the environment filled with all the analysis tools you may ever need.
                        </p>

                        <hr/> 
                      
                    </div>
                </div> 

                
                <div className="infoframe zero_margin_top">
                    <button className="button" onClick={()=>navigate("/work")}>
                        BACK
                    </button>
                </div> 

                <Footer />
                
            </div>
        )
}

export default GenerativeContent;